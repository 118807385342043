<template>
  <div>
    <div class="addtask">
      <b-modal
        :id="id"
        cancel-variant="outline-secondary"
        centered
        size="lg"
        class="addtask-modal"
        :hide-footer="true"
        @hidden="clearForm"
        @shown="getTaskDetails"
      >
        <template #modal-title>
          <h3 class="addtask-from-title text-uppercase">
            <span>
              {{
                taskID ? task_title : boardStatus ? boardStatus.name : " "
              }}</span>
          </h3>
        </template>
        <validation-observer ref="sprintTasksRules">
          <b-row class="addtask-content">
            <!-- Select Title Block -->
            <b-col cols="12">
              <b-form-group
                label=""
                label-for="title"
                class="mt-1 to-do-input auto-resize-teaxtarea"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Task name"
                  rules="required"
                >
                  <textarea
                    ref="textarea"
                    v-model="title"
                    name="Task name"
                    placeholder="Task Name..."
                    :disabled="!addEditRights"
                    @input="resizeTextarea"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Select Assignee Block -->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label="assignee"
                label-for="assignee"
                label-cols="4"
                label-cols-lg="3"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Assignee"
                  rules="required"
                >
                  <v-select
                    v-model="assignee_id"
                    label="name"
                    name="assignee_id"
                    :options="assigneeUsers"
                    :reduce="(u) => u.id"
                    placeholder="Select Any Member"
                    class="select-size-lg select-task-option"
                    :disabled="!addEditRights"
                  >
                    <template v-slot:option="option">
                      <b-avatar
                        size="30"
                        :src="option.picture"
                        :text="option.name.charAt(0)"
                        class="mr-1"
                      />
                      <img
                        :src="option.img"
                        alt=""
                      >
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Choese Due date Block -->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label="Due date"
                label-for="due_date"
                label-cols="4"
                label-cols-lg="3"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Due date"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    @click="toggleDueDate"
                  >
                    <flat-pickr
                      id="astartdate"
                      ref="dueDate"
                      v-model="due_date"
                      class="form-control"
                      :config="configDueDate"
                      placeholder="Due date"
                      name="due_date"
                      :disabled="!addEditRights"
                      :style="!addEditRights ? 'cursor:default' : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger d-block">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Select Priority  Block-->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label="priority"
                label-for="priority"
                label-cols="4"
                label-cols-lg="3"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Priority"
                  rules="required"
                >
                  <v-select
                    id="priority"
                    v-model="priority"
                    label="name"
                    :options="allPriorities"
                    class="select-size-lg select-task-option"
                    name="priority"
                    placeholder="Select Any Priority"
                    :reduce="(p) => p.value"
                    :disabled="!addEditRights"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- select task type -->
            <b-col
              cols="12"
              lg="6"
              class="project-select"
            >
              <b-form-group
                label="Status"
                label-for="Status"
                label-cols="4"
                label-cols-lg="3"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <v-select
                    id="task_status"
                    v-model="task_status"
                    label="name"
                    :options="projectTaskStatus"
                    class="select-size-lg select-task-option"
                    name="task_status"
                    placeholder="Select Any Status"
                    :reduce="(status) => status.id"
                    :disabled="!addEditRights"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- multiple select tag  -->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label="Tag"
                label-for="tag"
                label-cols="4"
                label-cols-lg="3"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tags"
                  rules="required"
                >
                  <v-select
                    id="tags"
                    v-model="tags"
                    hide-details
                    multiple
                    name="tags"
                    label="name"
                    value="name"
                    :options="$store.state.app.tagList"
                    class="select-size-lg task-tag"
                    placeholder="Select Any Tag"
                    :close-on-select="false"
                    :disabled="!addEditRights"
                    :class="{ 'disable-tags': !addEditRights }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Estimated houres block -->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label="Est. hr"
                label-for="estimatedhours"
                label-cols="4"
                label-cols-lg="3"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Estimated hours"
                  rules="required|numeric|min_value:0|max_value:50"
                >
                  <b-input-group
                    id="estimatedhours"
                    append="Hours"
                    class="input-group-merge estimatedhours"
                  >
                    <b-form-input
                      id="estimatedhours"
                      v-model="estimated_hours"
                      type="number"
                      placeholder="Estimated hours"
                      name="estimatedhours"
                      :disabled="!addEditRights"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Description Block -->
            <b-col cols="12">
              <b-form-group
                label-for="task-description"
                class="text-editer"
              >
                <div class="description">
                  <p>Description</p>
                </div>
                <b-form-textarea
                  v-if="disableNormalEditor"
                  id="title"
                  placeholder="Add a task Description"
                  name="project_name"
                  rows="6"
                  :disabled="!addEditRights"
                  @click="disablededitor"
                />
                <quill-editor
                  v-if="disableQuillEditor"
                  v-model="description"
                  :options="editorOption"
                  :disabled="!addEditRights"
                >
                  <div
                    id="toolbar"
                    slot="toolbar"
                  >
                    <!-- Add font size dropdown -->
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-font">
                      <option selected="selected" />
                      <option value="serif" />
                      <option value="monospace" />
                    </select>
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>

                    <button class="ql-clean" />
                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                    <button
                      id="ql-link"
                      class="ql-link"
                    >
                      <feather-icon
                        icon="Link2Icon"
                        color="white"
                      />
                    </button>
                    <!-- You can also add your own -->
                  </div>
                </quill-editor>
              </b-form-group>
            </b-col>
            <!-- File Attachments  block-->
            <b-col
              cols="12"
              :class="{
                'mb-2': !addEditRights
              }"
            >
              <fileUpload
                v-if="!getDataLoader"
                :manual-file-added="task_attachments"
                @onChange="handleFileUpload($event)"
                @onFileRemove="remove_attachments = $event"
              />
            </b-col>

          </b-row>
          <div
            v-if="addEditRights"
            class="text-right px-3 mb-2 mt-1"
          >
            <b-button
              class="addtask-start-btn"
              :disabled="loader"
              @click="validationForm"
            >
              <b-spinner
                v-if="loader"
                small
                class="spinner"
              />
              {{ taskID ? "Update" : "Create" }}
            </b-button>
          </div>
          <!-- Activity, Add Comment and View Comment Block Show -->
          <div
            v-if="taskID"
            class="feed-back"
          >
            <!-- Activity collapse Block -->
            <app-collapse accordion>
              <app-collapse-item title="Activity">
                <div class="activity">
                  <!-- <h4>Activity</h4> -->
                  <div
                    v-for="(items, index) in allActivity"
                    :key="index"
                    class="activity-content"
                  >
                    <b-avatar />
                    <div
                      class="right-side-content"
                      :style="{ whiteSpace: 'pre-line' }"
                    >
                      <p>{{ items.description | strippedContent }}</p>
                      <small>{{ items.updated_at | dateWithTimeFormat }}</small>
                    </div>
                  </div>
                </div>
              </app-collapse-item>
            </app-collapse>
            <!-- Add Comment Block -->
            <hr v-if="allCommentsData.length">
            <div
              v-if="allCommentsData.length"
              class="feed-back2"
            >
              <app-collapse accordion>
                <app-collapse-item
                  title="Comments"
                  class="pt-25"
                >
                  <div class="activity">
                    <!-- <h4>Activity</h4> -->
                    <div class="">
                      <div
                        v-for="(items, index) in allCommentsData"
                        :key="index"
                        class="activity-content d-flex justify-content-between align-items-start"
                      >
                        <div class="d-flex">
                          <b-avatar />
                          <div class="right-side-content ml-1">
                            <p>
                              {{ items.user.name }} {{ items.user.last_name
                              }}
                            </p>
                            <div v-html="items.comment" />
                            </p>
                            <small>{{ items.updated_at | dateWithTimeFormat }}
                            </small>
                          </div>
                        </div>

                        <div class="boardDropdown">
                          <b-dropdown
                            toggle-class="text-decoration-none"
                            no-caret
                          >
                            <template v-slot:button-content>
                              <button class="edit-del-button">
                                <feather-icon
                                  icon="MoreHorizontalIcon"
                                  size="21"
                                  class="align-middle edit-data-delete"
                                />
                              </button>
                            </template>
                            <b-dropdown-item class="edit-delete-item">
                              <feather-icon
                                icon="EditIcon"
                                size="16"
                                class="mr-50 edit-icons"
                              />
                              <span
                                class="edit-data"
                                @click="editComment(items.comment, items.id)"
                              >Edit Comment</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </app-collapse-item>
              </app-collapse>
            </div>
            <hr>
            <validation-observer
              ref="tasksCommentRules"
              v-slot="{ invalid }"
            >
              <div class="comment text-editer">

                <div
                  v-if="taskID"
                  class="description"
                >
                  <p>Add Comments</p>
                  <!-- <p><span>!</span>Click to edit</p> -->
                </div>
                <div class="comment-content comment-editer text-editer">
                  <!-- <b-avatar
                  :src="
                    require('@/assets/images/portrait/small/avatar-s-20.jpg')
                  "
                  v-if="disableNormalComment && taskID"
                /> -->
                  <b-form-input
                    v-if="disableNormalComment && taskID"
                    id="basicInput"
                    placeholder="Add comment"
                    @click="disabledcomment"
                  />
                  <validation-provider
                    #default="{ errors }"
                    name="Comment"
                    rules="imageNotAllowed"
                  >
                    <quill-editor
                      v-if="disableQuillComment && taskID"
                      v-model="comment"
                      :options="editorOption1"
                      class="flex-grow-1 overflow-hidden"
                    >
                      <div
                        id="toolbar1"
                        slot="toolbar"
                      >
                        <!-- Add font size dropdown -->
                        <select class="ql-size">
                          <option value="small" />
                          <!-- Note a missing, thus falsy value, is used to reset to default -->
                          <option selected />
                          <option value="large" />
                          <option value="huge" />
                        </select>

                        <select class="ql-font">
                          <option selected="selected" />
                          <option value="serif" />
                          <option value="monospace" />
                        </select>
                        <!-- Add a bold button -->
                        <button class="ql-bold">
                          Bold
                        </button>
                        <button class="ql-italic">
                          Italic
                        </button>
                        <button class="ql-underline">
                          Underline
                        </button>

                        <button class="ql-clean" />
                        <select class="ql-align">
                          <option selected="selected" />
                          <option value="center" />
                          <option value="right" />
                          <option value="justify" />
                        </select>
                        <button class="ql-link" />
                        <!-- You can also add your own -->
                      </div>
                    </quill-editor>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>

              <div
                v-if="taskID && disableComment"
                class="pl-3 pb-1"
              >
                <b-button
                  class="addtask-start-btn comment-btn px-2 py-50 font-weight-normal"
                  :disabled="commentValidation || invalid"
                  @click="commentClick"
                >
                  Save
                  <img
                    src="../../../assets/images/icons/send.svg"
                    alt="send-Icon"
                    class="ml-25"
                  >
                </b-button>
                <!-- Comments Show  -->
              </div>
            </validation-observer>
          </div>
        </validation-observer>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { eventBus } from '@/main'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from 'vue-quill-editor'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vue2Dropzone from 'vue2-dropzone'
import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import fileUpload from '../../../components/FileUpload/index.vue'

extend('imageNotAllowed', {
  validate: value => {
    // Check if the content contains any image tag
    const containsImage = /<img[^>]+src="[^"]+"[^>]*>/i.test(value)
    return !containsImage
  },
  message: 'Image is not allowed in the comment box',
})
export default {
  name: 'AddTask',
  components: {
    BButton,
    BModal,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    AppCollapse,
    AppCollapseItem,
    FeatherIcon,
    vueDropzone: vue2Dropzone,
    fileUpload,
  },
  directives: {
    'b-modal': VBModal,
  },
  filters: {
    strippedContent(string) {
      return string.replace(/<\/?[^>]+>/gi, ' ')
    },
  },

  props: {
    projectDtl: {
      // type: Object,
      required: true,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: 'addtaskdata',
    },
    boardStatus: {
      type: Object,
    },
    taskID: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'list',
    },
    from: {
      type: String,
      default: 'project',
      required: false,
    },
  },

  data() {
    return {
      disableQuillEditor: false,
      disableNormalEditor: true,
      disableQuillComment: false,
      disableNormalComment: true,
      disableComment: false,
      comment: null,
      editTask: true,
      remove_attachments: [],
      allActivity: [],

      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
        placeholder: 'Type anything',
      },
      editorOption1: {
        modules: {
          toolbar: '#toolbar1',
        },
        placeholder: 'Add a comment',
      },
      options: [
        {
          title: 'hello',
          img: require('../../../assets/images/avatars/1-small.png'),
        },
      ],
      milestones: [],
      milestone: null,
      sprints: [],
      sprint_id: null,
      title: null,
      description: null,
      tasksStatus: [],
      all_tags: [],
      tags: [],
      assignee_id: [],
      reporter_id: [],
      estimated_hours: null,
      actual_hours: null,
      priority: 'med',
      attachments: [],
      task_attachments: [],
      task_status: null,
      task_title: null,
      // type: null,
      loader: false,
      due_date: null,
      configDueDate: {
        minDate: null,
        maxDate: null,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        clickOpens: false,
      },
      configEstimatedDate: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
      },
      allCommentsData: [],
      images: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/project/task/image-store`,
        maxFiles: 20,
        uploadMultiple: true,
        parallelUploads: 100,
        // thumbnailWidth: 150,
        maxFilesize: 10,
        acceptedFiles:
          '.jpeg, .jpg, .png, .txt, .pdf, .ppt,.pptx, .doc, .csv, .docx, .xls, .xlsx',
        dictDefaultMessage:
          "<b class='drop-title'>Drag & drop files</b> <br> <p class='drop-req'>Supported formats: JPEG, PNG, JPG, <br /> TXT, PDF, PPT, DOC, CSV, DOCX, XLS, XLSX <br /> ",
        addRemoveLinks: true,
      },
      pdfFile: [],
      // activeUserList: [],
      isValidFile: true,
      FileErrorMsg: null,
      errorFileList: [],
      getDataLoader: false,
    }
  },
  computed: {
    commentValidation() {
      // const str =  this.comment ? this.comment.replace(/<p>\s*<\/p>|<p><br><\/p>|<br>/g, "") :"";
      const plainText = this.comment && this.comment.length ? this.comment.replace(/<[^>]+>/g, '').replace(/\s+/g, '') : ''

      return !(plainText.length > 0)
    },
    assigneeUsers() {
      //
      if (
        this.$store.state.app.projectUsers

        && this.$store.state.app.projectUsers.length
      ) {
        const users = []

        this.$store.state.app.projectUsers.forEach(element => {
          if (!this.reporter_id || this.reporter_id != element.user_id) {
            element.name = element.full_name
            users.push(element)
          }
        })

        return users
      }
      return []
    },
    projectTaskStatus() {
      return this.$store.state.app.projectTaskStatus.filter(a => (a.id !== 'archive'))
    },

    addEditRights() {
      if (this.userInfo && this.userInfo.role === 'SA') {
        return true
      }
      if (
        this.$store.state.app.projectUsers
        && this.$store.state.app.projectUsers.length
      ) {
        const user = this.$store.state.app.projectUsers.find(
          pu => pu.id === this.userInfo.id,
        )
        if (
          user
          && (user.access_rights === 'project_owner'
            || user.access_rights === 'co_ordinator')
        ) {
          return true
        }
      }
      return false
    },

    reporterUsers() {
      if (
        this.projectDtl
        && this.projectDtl.project_user
        && this.projectDtl.project_user.length
      ) {
        const users = []
        this.projectDtl.project_user.forEach(element => {
          if (!this.assignee_id || this.assignee_id != element.user_id) {
            element.users.name = element.users.full_name
            users.push(element.users)
          }
        })
        return users
      }
      return []
    },
  },
  watch: {
    milestone(nv) {
      if (nv && nv.sprints) {
        this.sprint_id = null
        this.sprints = nv.sprints.length ? nv.sprints : []
      }
    },
    boardStatus(value) {
      this.task_status = value.id
    },
  },
  updated() {
    if (!this.taskID && !this.boardStatus) {
      this.task_status = this.$store.state.app.projectTaskStatus
        && this.$store.state.app.projectTaskStatus[0]
        && this.$store.state.app.projectTaskStatus[0].id
        ? this.$store.state.app.projectTaskStatus[0].id
        : null
    }
  },
  mounted() {
    if (this.taskID) {
      this.getTaskDetails()
    }

    if (this.boardStatus) {
      this.task_status = this.boardStatus.status
    } else {
      this.task_status = this.$store.state.app.projectTaskStatus
        && this.$store.state.app.projectTaskStatus[0]
        && this.$store.state.app.projectTaskStatus[0].id
        && this.$store.state.app.projectTaskStatus[0].id
    }

    this.getProjectPlanLists()
    // this.getTagsData();
    if (this.$route.name == 'sprint') {
      // set not start sprint date into due_date
      this.configDueDate.minDate = this.projectDtl
        && this.projectDtl.sprints
        && this.projectDtl.sprints.estimated_start_date
      this.configDueDate.maxDate = this.projectDtl
        && this.projectDtl.sprints
        && this.projectDtl.sprints.estimated_end_date
    } else {
      // set Active sprint date into due_date
      this.configDueDate.minDate = this.projectDtl
        && this.projectDtl.active_sprint
        && this.projectDtl.active_sprint.estimated_start_date
      this.configDueDate.maxDate = this.projectDtl
        && this.projectDtl.active_sprint
        && this.projectDtl.active_sprint.estimated_end_date
    }
  },
  methods: {
    /**
     * Resizeing of title textarea
     */
    resizeTextarea() {
      this.$refs.textarea.style.height = 'auto'
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`
    },

    /**
     * disabled Description And open Quill Editoe
     */
    disablededitor() {
      this.disableQuillEditor = true
      this.disableNormalEditor = false
    },

    /**
     * disabled Comment And open Quill Editoe
     */
    disabledcomment() {
      this.disableQuillComment = true
      this.disableNormalComment = false
      this.disableComment = true
    },

    async getProjectPlanLists() {
      const input = {
        project_id: this.projectDtl.id,
      }
      const response = await this.getHTTPPostResponse(
        'project/plan/lists',
        input,
        false,
      )
      if (response && response.data) {
        const data = response.data.project_plans && response.data.project_plans.length
          ? response.data.project_plans
          : []
        const resNew = []
        data.forEach(element => {
          if (element && element.status != 'complete') {
            resNew.push(element)
          }
        })
        this.milestones = resNew
        this.setCurrentMilestoneSprint()
      }
      this.loading = false
    },
    setCurrentMilestoneSprint() {
      // check active milestone
      if (this.projectDtl && this.projectDtl.active_milestone) {
        const milestone = this.milestones.find(
          m => m.id === this.projectDtl.active_milestone.id,
        )
        if (milestone) {
          this.milestone = milestone
          // check active sprint
          setTimeout(() => {
            if (this.projectDtl.active_sprint) {
              this.sprint_id = this.projectDtl.active_sprint.id
            }
          }, 500)
        }
      }
      if (
        this.projectDtl && this.projectDtl.sprints
          ? this.projectDtl.sprints.status
          : this.projectDtl == 'not_started'
      ) {
        this.milestone = this.projectDtl.milestone
        setTimeout(() => {
          if (this.projectDtl.sprints) {
            this.sprint_id = this.projectDtl.sprints.id
          }
        }, 500)
      }
    },
    /**
     * Get tags data
     */
    async getTagsData() {
      const input = {
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/tag',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.all_tags = data.count ? data.tags : []
      }
    },
    /**
     * Click save button And check validationForm
     */
    validationForm() {
      this.$refs.sprintTasksRules.validate().then(success => {
        if (success && this.isValidFile) {
          if (this.taskID) {
            this.updateTask()
          } else {
            this.createNewTasks()
          }
        } else {
          return false
        }
      })
    },
    /**
     * save the client form details
     */
    async createNewTasks() {
      this.loader = true

      const taskData = {
        project_id: this.projectDtl.id,
        milestone_id: this.milestone.id,
        sprint_id: this.sprint_id || null,
        status_id: this.task_status,
        assignee: this.assignee_id,
        title: this.title,
        description: this.description || '',
        priority: this.priority,
        due_date: this.due_date,
        estimated_hours: this.estimated_hours,
        actual_hours: this.actual_hours,

      }

      if (this.reporter_id && this.reporter_id.length) {
        taskData.reporter_id = this.reporter_id
      }
      if (this.tags && this.tags.length) {
        taskData.tags = this.tags.map(tag => tag.name)
      }

      if (this.pdfFile && this.pdfFile.length) {
        taskData.files = this.pdfFile ? await Promise.all(this.pdfFile.map(async file => ({
          name: file.name,
          file: await this.convertFileToBase64(file),
        }))) : []
      }
      const response = await this.getHTTPPostResponse(
        'project/task/create',
        taskData,
        true,
      )
      if (response && response.status == 200) {
        if (this.from == 'project') {
          if (this.type == 'board') {
            eventBus.$emit('reloadBoardCompleteTaskList', true)
          } else {
            eventBus.$emit('reloadCompleteTaskList', true)
            eventBus.$emit('closeTaskDtl', true)
          }
        } else {
          eventBus.$emit('reloadGeneralProjectBoardTaskList', true)
        }
        this.$refs.sprintTasksRules.reset()
        this.$root.$emit('bv::toggle::modal', this.id)
        this.clearForm()
      }

      this.loader = false
    },
    /**
     * Edit the client form details
     */
    async getTaskDetails() {
      if (this.taskID) {
        this.loader = true
        this.getDataLoader = true
        this.task_attachments = []
        const input = {
          project_id: this.projectDtl.id,
        }
        const response = await this.getHTTPGetResponse(
          `project/task/view/${this.taskID}`,
          input,
          false,
        )
        if (response && response.status == 200) {
          if (response.data && response.data.project_task) {
            this.loader = false
            this.allActivity = response.data.activity
            const { project_task } = response.data
            this.title = project_task.title
            this.description = project_task.description == 'null'
              ? ''
              : project_task.description
            if (this.description == '') {
              this.disableNormalEditor = true
              this.disableQuillEditor = false
            } else {
              this.disableNormalEditor = false
              this.disableQuillEditor = true
            }
            this.project = project_task.project
            this.milestone = project_task.project_plan_milestone
            this.sprint = project_task.project_plan_sprint
            this.priority = project_task.priority
            this.estimated_hours = project_task.estimated_hours
            this.actual_hours = project_task.actual_hours
            this.assignee_id = project_task.assignee
            this.assignee = project_task.assignee_user
            this.reporter_id = project_task.reporter_id
            this.reporter = project_task.reporter_user
            this.task_status = project_task.status_id

            this.task_title = project_task.status.name
              .replace('_', ' ')
              .toUpperCase()
            this.task_attachments = project_task.project_task_attachments
            this.allCommentsData = project_task.project_task_comments
            if (project_task.tags && project_task.tags.length) {
              const tags = []
              project_task.tags.forEach(tag => {
                tags.push({
                  name: tag.tag,
                  id: tag.tag,
                  value: tag.tag,
                  label: tag.tag,

                })
              })
              this.tags = tags
              this.edit_task_tags = project_task.tags
            }

            this.due_date = project_task.due_date

            if (this.$route.name == 'sprint') {
              this.configDueDate = {
                minDate:
                  this.projectDtl
                  && this.projectDtl.sprints
                  && this.projectDtl.sprints.estimated_start_date,
                maxDate:
                  this.projectDtl
                  && this.projectDtl.sprints
                  && this.projectDtl.sprints.estimated_end_date,
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                clickOpens: false,
              }
            } else {
              this.configDueDate = {
                minDate:
                  this.projectDtl
                    && this.projectDtl.active_sprint
                    && this.projectDtl.active_sprint.estimated_start_date
                    ? this.projectDtl.active_sprint.estimated_start_date
                    : null,
                maxDate:
                  this.projectDtl
                    && this.projectDtl.active_sprint
                    && this.projectDtl.active_sprint.estimated_end_date
                    ? `${this.projectDtl.active_sprint.estimated_end_date} 23:59`
                    : null,
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                clickOpens: false,
              }
            }
            this.remove_attachments = []
          }
        }
        this.loader = false
        this.getDataLoader = false
      }
    },
    /**
     * Update the client form details
     */
    async updateTask() {
      this.loader = true
      const taskData = {
        _method: 'PUT',
        project_id: this.projectDtl.id,
        milestone_id: this.milestone.id,
        sprint_id: this.sprint_id || null,
        status_id: this.task_status,
        assignee: this.assignee_id,
        title: this.title,
        description: this.description || '',
        priority: this.priority,
        due_date: this.due_date,
        reporter_id: this.reporter_id || null,
        tags: this.tags.map(tag => tag.name),
        estimated_hours: this.estimated_hours,
        actual_hours: this.actual_hours,
        files: this.pdfFile ? await Promise.all(this.pdfFile.map(async file => ({
          name: file.name,
          file: await this.convertFileToBase64(file),
        }))) : [],
        remove_file_ids: this.remove_attachments || [],
        edit_task_tags: this.edit_task_tags || [],
      }

      const response = await this.getHTTPPostResponse(
        `project/task/update/${this.taskID}`,
        taskData,
        true,
      )
      if (response && response.status == 200) {
        if (this.from == 'project') {
          if (this.type == 'board') {
            eventBus.$emit('reloadBoardCompleteTaskList', true)
          } else {
            eventBus.$emit('reloadCompleteTaskList', true)
            eventBus.$emit('closeTaskDtl', true)
          }
        } else {
          eventBus.$emit('reloadGeneralProjectBoardTaskList', true)
        }
        this.$emit('disableTaskID')
        this.$refs.sprintTasksRules.reset()
        this.$root.$emit('bv::toggle::modal', this.id)
        this.clearForm()
        this.images = []
      }
      this.loader = false
    },
    /**
     * Add comments Data
     */
    async commentClick() {
      const formData = {
        project_task_id: this.taskID,
        comment: this.comment,
        project_id: this.projectDtl.name,
        comment_id: this.comment_id,
      }
      const response = await this.getHTTPPostResponse(
        'project/task/add',
        formData,
        true,
      )
      if (response && response.status == 200) {
        this.comment = null
        this.comment_id = null
        this.clearForm()
        this.getTaskDetails()
        this.$root.$emit('bv::toggle::modal', this.id)
      }
    },
    /**
     * Edit Comments Data
     */
    editComment(val, id) {
      this.disableNormalComment = false
      this.disableQuillComment = true
      this.disableComment = true
      this.comment_id = id
      this.comment = val
    },
    /**
     * Remove Attachments
     */

    removeAttachments(index, id) {
      this.remove_attachments.push(id)
      this.task_attachments.splice(index, 1)
      this.attachments.splice(index, 1)
    },
    /**
     * Clear Form
     */
    clearForm() {
      this.milestone = null
      this.sprint_id = null
      this.task_status = null
      this.assignee_id = null
      this.title = null
      this.description = null
      this.priority = null
      this.reporter_id = null
      this.tags = []
      this.due_date = null
      this.estimated_hours = null
      this.actual_hours = null
      this.pdfFile = []
      this.attachments = []
      this.task_attachments = []
      this.loader = false
      this.disableNormalEditor = true
      this.disableQuillEditor = false
      this.disableQuillComment = false
      this.disableNormalComment = true
      this.disableComment = false
      this.comment = null
      this.images = []
      this.edit_task_tags = []
      this.$emit('disableTaskID')
      this.setCurrentMilestoneSprint()
    },
    toggleDueDate() {
      this.$refs.dueDate.fp.toggle()
    },

    handleFileUpload($event) {
      this.pdfFile = $event.file
      this.isValidFile = $event.isValidFile
    },
  },
}
</script>

<style>
@import "../../../assets/scss/component-css/list.css";

.vs--disabled.disable-tags .vs__selected {
  background-color: #f97753 !important;
}

.task-tag .vs__selected {
  padding: 4px 11px !important;
}
</style>
